import React from "react";
import { Box, CircularProgress, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, SxProps, Theme } from "@mui/material";
import AdbOutlinedIcon from '@mui/icons-material/AdbOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';

interface SidebarIconProps {
    code: string
}

interface SidebarItemProps {
    children?: React.ReactNode
    onClick?: () => void
}

interface SidebarIconProps {
    code: string,
    sx?: SxProps<Theme>
}

const iconCodeForExtension = (fileName: string) => {
    const ext = fileName.split('.').pop()?.toLowerCase();
    switch(ext) {
        case 'pdf': return 'file.pdf'
        case 'jpg': case 'jpeg': case 'png': return 'file.image'
        default: return 'file.unknown'
    }
}

export const IconForExtension = ({ fileName, sx }: { fileName: string, sx?: SxProps<Theme> }) => {
    const iconCode = iconCodeForExtension(fileName);
    return <SidebarItemIcon code={iconCode} sx={sx} />
}

export const SidebarItemIcon = ({ code, sx }: SidebarIconProps) => {
    switch (code) {
        case 'Dokumendid': return <InsertLinkOutlinedIcon sx={sx} />
        case 'EDI': return <AlternateEmailOutlinedIcon sx={sx} />
        case 'Failid': return <AttachFileOutlinedIcon sx={sx} />
        case 'code': return <CodeOutlinedIcon sx={sx} />
        case 'settings': return <SettingsOutlinedIcon sx={sx} />
        case 'download': return <DownloadOutlinedIcon sx={sx} />
        case 'file.pdf': return <PictureAsPdfOutlinedIcon sx={sx} />
        case 'file.image': return <InsertPhotoOutlinedIcon sx={sx} />
        case 'file.unknown': return <QuizOutlinedIcon sx={sx} />
        default: return <AdbOutlinedIcon sx={sx} />
    }
}

export const SidebarIcon = ({ code }: SidebarIconProps) =>
    <ListItemIcon sx={{ marginRight: -3 }}>
        <SidebarItemIcon code={code} />
    </ListItemIcon>

export const SidebarItemButton = ({ children, onClick }: SidebarItemProps) => <ListItemButton
    sx={{ pl: 3 }}
    onClick={onClick}
>{children}</ListItemButton>

export const SidebarItem = ({ children, onClick }: SidebarItemProps) => <ListItem
    sx={{ pl: 3 }}
    onClick={onClick}
>{children}</ListItem>

