import React from 'react';
import { EditFlavor } from '../../../app/types';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import TextEdit from './TextEdit';
import ClearIcon from '@mui/icons-material/Clear';
import { useIntl } from 'react-intl';
import { useDataConnector } from '../../../redux/store';
import { useAppDispatch } from '../../../app/hooks';

interface ClearableRangeEditProps {
    docPath: string
    fieldFrom: string
    fieldUntil: string
    labelFrom?: string
    labelUntil?: string
    flavor?: EditFlavor
    autoFocus?: boolean
}

export default function ClearableRangeEdit(props: ClearableRangeEditProps) {

    const intl = useIntl();
    const dc = useDataConnector(props.docPath);
    const dispatch = useAppDispatch();
    const obj = dc.selectRecord(props.docPath);
    const valFrom = dc.getValue(obj, props.fieldFrom);
    const valUntil = dc.getValue(obj, props.fieldUntil);

    const handleClick = () => {
        if (undefined !== valFrom)
            dispatch(dc.updateFieldData({ docPath: props.docPath, field: props.fieldFrom, val: undefined }));
        if (undefined !== valUntil)
            dispatch(dc.updateFieldData({ docPath: props.docPath, field: props.fieldUntil, val: undefined }));

    }

    return <Stack direction="row" spacing={2} useFlexGap>
        <TextEdit {...props} field={props.fieldFrom} label={props.labelFrom} />
        <TextEdit {...props} field={props.fieldUntil} label={props.labelUntil} />
        <Tooltip title={intl.formatMessage({ id: 'btn_remove' })}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                >
                    <ClearIcon />
                </IconButton>
            </Box>
        </Tooltip>
    </Stack>
}
