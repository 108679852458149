import React from 'react';

import { Box, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useAppDispatch } from '../../../app/hooks';
import { EditFlavor } from '../../../app/types';
import { FormattedMessage } from 'react-intl';
import { useDataConnector } from '../../../redux/store';
import EnhancedFormControl from './EnhancedFormControl';
import InputSkeleton from '../InputSkeleton';

export interface SelectEditItem {
    value: any
    label: string
}

interface SelectEditProps {
    docPath: string
    field: string
    label?: string
    flavor?: EditFlavor
    items: SelectEditItem[]
    fullWidth?: boolean
    translateItems?: boolean
    nullValue? : string | number
    autoFocus?: boolean
}

export default function SelectEdit({ docPath, field, label, flavor, items, fullWidth, translateItems = true, nullValue, autoFocus }: SelectEditProps) {
    const dc = useDataConnector(docPath);
    const dispatch = useAppDispatch();
    const obj = dc.selectRecord(docPath);
    const error = dc.getValidationError(docPath, field);
    const isReady = dc.isRecordReadyForEdit(docPath);
    const isReadOnly = dc.isReadOnly(docPath);

    const defaultValue = dc.getValue(obj, field) || (nullValue === undefined ? '' : nullValue);

    const handleChange = (event: SelectChangeEvent) => {
        const newVal = event.target.value;
        if (newVal !== defaultValue)
            dispatch(dc.updateFieldData({ docPath: docPath, field: field, val: newVal }));
    }

    const labelMsg = <FormattedMessage id={label || field} />;

    // console.log('RENDER SelectEdit', docPath, field, '=', defaultValue, 'nv=', nullValue, error || '<no error>', isReady ? 'ready' : 'not ready');

    return <Box>
        <EnhancedFormControl
            docPath={docPath}
            field={field}
            flavor={flavor}
            fullWidth
        >
            {isReady ? <>
                <InputLabel id={'lbl_' + field}>{labelMsg}</InputLabel>

                <Select
                    labelId={'lbl_' + field}
                    id={'select_' + field}
                    value={defaultValue}
                    label={labelMsg}
                    error={!!error}
                    onChange={handleChange}
                    readOnly={isReadOnly}
                    autoFocus={autoFocus}
                >
                    {items.map((item, index) => <MenuItem key={index} value={item.value}>
                        {translateItems ? <FormattedMessage id={item.label} /> : item.label}
                    </MenuItem>)}
                </Select>
                <FormHelperText
                    error={!!error}
                >{!!error ? <FormattedMessage id={error} /> : undefined}</FormHelperText>
            </> : <InputSkeleton />}
        </EnhancedFormControl>
    </Box>
}