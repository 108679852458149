import { RootState } from "../redux/store";
import { getAuthToken } from "../redux/auth/authSlice";
import { isDevMode } from "../configs/appConfig";

export class APIError extends Error {
    additional: any;
    constructor(message: string, additional?: any) {
        super(message);
        this.name = 'APIError';
        this.additional = additional;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

function getRequestHeaders(authkey: string, contentType: string): HeadersInit {
    return {
        'Content-Type': contentType,
        'X-ProfitToken': authkey,
        'X-Debug': '1',     // TODO remove if in production
    };
}

type ApiContentType = 'application/json'
    | 'application/x-www-form-urlencoded;charset=UTF-8'
    | 'multipart/form-data'

/**
 * Common method to access Profit API
 * @param method GET/POST/PUT/DELETE
 * @param authkey Authentication key
 * @param url url to fetch
 * @param payload 
 * @param contentType 
 * @returns response from API, parsed as JSON and checked for errors
 */
export async function request(
    method: string,
    authkey: string,
    url: string,
    payload?: FormData | string,
    contentType: ApiContentType = 'application/json'
) {
    if (isDevMode) console.log(method + ' ' + url, !!payload ? payload : '');

    const response = await fetch(
        url, {
        method: method,
        headers: getRequestHeaders(authkey, contentType),
        body: payload
    });
    const j = await response.json();
    checkResponse(j);
    return j;
}

/**
 * @brief Shortcut for GET request to current database. Endpoint is appended to current database uri
 * @param state 
 * @param endpoint 
 * @param query 
 * @returns 
 */
export async function getFromCurrentDB(state: RootState, endpoint: string, query?: object) {
    const db = state.databases.currentDatabase!;
    return await get(state, db.uri + '/' + endpoint, query);
}

export async function getRecordFromCurrentDB(state: RootState, endpoint: string, query?: object) {
    const ret = await getFromCurrentDB(state, endpoint, query);
    return ret.data[0];
}

export async function get(state: RootState, endpoint: string, query?: object) {
    const db = state.databases.currentDatabase!;
    const queryString = (!!query ? ('?' + Object.entries(query).map(i => i.map(i => encodeURIComponent(i)).join('=')).join('&')) : '');
    const url = db.api + endpoint + (queryString === '' ? '' : '/' + queryString);
    const token = await getAuthToken();
    return await request('GET', token, url, undefined, 'application/json');
}

export async function del(state: RootState, endpoint: string) {
    const db = state.databases.currentDatabase!;
    const url = db.api + endpoint;
    const token = await getAuthToken();
    return await request('DELETE', token, url);
}

export async function postForm(state: RootState, endpoint: string, payload: FormData) {
    const db = state.databases.currentDatabase!;
    const url = db.api + endpoint;
    const token = await getAuthToken();
    return await request('POST', token, url, payload, undefined);
}

export async function postFile(state: RootState, endpoint: string, payload: File) {
    const token = await getAuthToken()

    if (isDevMode) console.log('uploading file', payload)

    const db = state.databases.currentDatabase!
    const url = db.api + endpoint
    const method = 'POST'

    console.log('UPLOADING FILE to ', url);

    // try {
    const response = await fetch(
        url,
        {
            method: method,
            headers: {
                ...getRequestHeaders(token, payload.type),
                'content-length': `${payload.size}`,
            },
            body: payload
        }
    );
    const j = await response.json();
    console.log('response', j);
    checkResponse(j);
    return j;

    // } catch (error) {
    //     throw error;
    // }
}

export async function post(state: RootState, endpoint: string, payload: object) {
    const db = state.databases.currentDatabase!;
    const url = db.api + endpoint;
    const token = await getAuthToken();
    const pl = encodeURIComponent('data') + '=' + encodeURIComponent(JSON.stringify(payload));
    return await request('POST', token, url, pl, 'application/x-www-form-urlencoded;charset=UTF-8');
}

export async function put(state: RootState, endpoint: string, payload: object) {
    const db = state.databases.currentDatabase!;
    const url = db.api + endpoint;
    const token = await getAuthToken();
    const pl = encodeURIComponent('data') + '=' + encodeURIComponent(JSON.stringify(payload));
    return await request('PUT', token, url, pl, 'application/x-www-form-urlencoded;charset=UTF-8');
}

type ApiErrorListenerFunc = (s: String) => void;
const errorListeners: ApiErrorListenerFunc[] = [];

export function addApiErrorListener(f: ApiErrorListenerFunc) {
    errorListeners.push(f);
}

function throwError(msg: string, additional?: any) {
    if (isDevMode) console.log('send error to ' + errorListeners.length + ' listeners');
    errorListeners.forEach(f => f(msg));
    throw new APIError(msg, additional);
}

const apiErrorTranslations = [
    {
        match: /General error.*PRIMARY or UNIQUE KEY.*ATTACHMENTS/,
        extractor: (input: string) => {
            const match = input.match(/"NIMI" = '([^']+)'/);
            if (match)
                return { name: match[1] };
            else
                return undefined;
        },
        message: 'error_attachment_unique_violation'
    }
];


/**
 * Analyses API response. If response means error, throws exception with (more or less) meaningfull message
 * @param {Object} response API response
 */
export function checkResponse(response: any) {
    let state: number = 0
    let message: string = '';

    try {
        state = Number.parseInt(response.status);
        message = response.message;
    } catch (e) {
        throwError('Wrong response format')
    }

    if (state !== 200) {
        if (!message || message === '')
            throwError('Wrong response format')
        let m;
        let additional: any = undefined;
        try {
            m = message.split('PROFITEXC_GENERAL ')[1].split(' At ')[0];
        } catch (e) {
            m = message;
        }

        apiErrorTranslations.forEach(t => {
            if (message.match(t.match)) {
                if (t.extractor) {
                    additional = t.extractor(message);
                    if (isDevMode) console.log('extracted', additional);
                    throwError(t.message, additional);
                } else
                    throwError(t.message);
            }
        });

        throwError(m);
    }
}