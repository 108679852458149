import React from 'react';

import { GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid"
import { GridValueType, TextAlign, ValueType } from "./types"
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { INTL_DATEFORMAT, INTL_TIMEFORMAT, SUM_FORMAT } from '../const';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

export interface DocGridColumn {
    name: string
    label?: string
    type?: GridValueType
    align?: TextAlign
    width?: number
}

export const modificationColumns: DocGridColumn[] = [
    {name: 'creator', width: 100},
    {name: 'd_created', width: 140, type: 'datetime'},
    {name: 'modifier', width: 100},
    {name: 'd_modified', width: 140, type: 'datetime'},
]

export const attachmentsCountColumns: DocGridColumn[] = [
    {name: 'mdatts', align: 'right', type: 'attachment_icon', label: 'attachment_icon', width: 30}
]

export const labelsColumns: DocGridColumn[] = [
    {name: 'labels', type: 'label_icon', label: 'label_icon', width: 80},
]

const gridCellIconParams = {
    style: { fontSize: 18 }
};

export interface DocGridTypeRendererDescriptor {
    headerRenderer?: (params: GridColumnHeaderParams<any, any, any>) => JSX.Element | null,
    valueFormatter?: (params: any) => string,
    cellRenderer?: (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => JSX.Element | null
};

export const gridTypeRenderers : {[key: string]: DocGridTypeRendererDescriptor} = {
    'decimal': {
        cellRenderer: (params) => isNaN(params.value) ? null : <FormattedNumber value={params.value} {...SUM_FORMAT} />
        // valueFormatter: (params) => isNaN(params.value) ? '' : intl.formatNumber(params.value, SUM_FORMAT)
    },
    'date': {
        cellRenderer: (params) => params.value ? <FormattedDate value={params.value} {...INTL_DATEFORMAT} /> : null
        // valueFormatter: (params) => !!params.value ? intl.formatDate(params.value, INTL_DATEFORMAT) : ''
    },
    'datetime': {
        cellRenderer: (params) => params.value ? <>
            <FormattedDate value={params.value} {...INTL_DATEFORMAT} />{' '}
            <FormattedDate value={params.value} {...INTL_TIMEFORMAT} />
        </> : null,
        // valueFormatter: (params) => !!params.value ? intl.formatDate(params.value, INTL_DATEFORMAT) + ' ' + intl.formatTime(params.value, INTL_TIMEFORMAT) : ''
    },
    'locked_icon': {
        headerRenderer: (params: GridColumnHeaderParams<any, any, any>) => <LockOutlinedIcon {...gridCellIconParams} />,
        valueFormatter: (params) => params.value ? '🔒' : '',
        cellRenderer: (params) => params.value ? <LockOutlinedIcon {...gridCellIconParams} /> : null
    },
    'attachment_icon': {
        headerRenderer: (params: GridColumnHeaderParams<any, any, any>) => <AttachFileOutlinedIcon {...gridCellIconParams} />,
    },
    'label_icon': {
        headerRenderer: (params: GridColumnHeaderParams<any, any, any>) => <LabelOutlinedIcon {...gridCellIconParams} />,
    },
    'emailed_status_icon': {
        headerRenderer: (params: GridColumnHeaderParams<any, any, any>) => <MarkEmailReadOutlinedIcon {...gridCellIconParams} />,
        valueFormatter: (params) => params.value == 1 ? '📧' : '',
        cellRenderer: (params) => params.value == 1 ? <MarkEmailReadOutlinedIcon {...gridCellIconParams} /> : null
    },
    'check_icon': {
        // headerRenderer: (params: GridColumnHeaderParams<any, any, any>) => <MarkEmailReadOutlinedIcon {...gridCellIconParams} />,
        valueFormatter: (params) => params.value == 1 ? '✓' : '',
        cellRenderer: (params) => params.value == 1 ? <CheckBoxOutlinedIcon {...gridCellIconParams} /> : null
    },
    'print_icon': {
        headerRenderer: (params: GridColumnHeaderParams<any, any, any>) => <PrintOutlinedIcon {...gridCellIconParams} />,
        valueFormatter: (params) => params.value == 1 ? '🖨' : '',
        cellRenderer: (params) => params.value == 1 ? <PrintOutlinedIcon {...gridCellIconParams} /> : null
    },
    'translated' : {
        cellRenderer: (params) => params.value ? <FormattedMessage id={params.value} /> : null
    }
}

export const getGridCellRenderer = (col: DocGridColumn) => {
    if(col && col.type)
        if(gridTypeRenderers[col.type])
            return gridTypeRenderers[col.type].cellRenderer;
    return undefined;
}

export const getGridValueFormatter = (col: DocGridColumn) => {
    if(col && col.type)
        if(gridTypeRenderers[col.type])
            return gridTypeRenderers[col.type].valueFormatter;
    return undefined;
}

const getDefaultGridHeaderRenderer = (col: DocGridColumn) => {
    // console.log('getDefaultGridHeaderRenderer', col.label || col.name);
    return (params: GridColumnHeaderParams<any, any, any>) => <FormattedMessage id={col.label || col.name} />;
}

export const getGridRenderHeader = (col: DocGridColumn) => {
    if(col && col.type)
        if(gridTypeRenderers[col.type])
            return gridTypeRenderers[col.type].headerRenderer || getDefaultGridHeaderRenderer(col);
    // return undefined;
    return getDefaultGridHeaderRenderer(col);
}

export const prepareGridColumns = (cols: DocGridColumn[]) => cols.map(col => ({
    field: col.name,
    width: col.width || 150,
    align: col.align || 'left',
    headerAlign: col.align || 'left',
    sortable: true,
    valueFormatter: getGridValueFormatter(col),
    renderCell: getGridCellRenderer(col),
    renderHeader: getGridRenderHeader(col)
}) as GridColDef);
