import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { DatabaseDescriptor, LoadableItemStatus } from '../../app/types'
import { getAuthUserUid } from '../auth/authSlice'
import { getFromProfitCentral } from '../../profit/profitcentral'

export interface DatabasesList {
    [key: string]: DatabaseDescriptor
}

export interface DatabaseTemplate {
    id: number
    caption: string
}

export type DatabaseCreationStatus = 'idle' | 'creating' | 'ready' | 'error' | 'archiving' | 'archived'

export interface DatabasesState {
    userUid?: string
    list: DatabasesList
    templates: DatabaseTemplate[]
    status: LoadableItemStatus 
    error?: any
    databaseCreationStatus: DatabaseCreationStatus
    databaseCreated?: DatabaseDescriptor
    databaseCreationError?: string
    currentDatabase?: DatabaseDescriptor
    currentDatabaseCode?: string
}

const initialState: DatabasesState = {
    list: {},
    databaseCreationStatus: 'idle',
    templates: [],
    status: 'invalid',
}

// Handle selectors loading
const databases: DatabasesState = initialState

const fetchFromDBApi = async (url: string) => 
    await getFromProfitCentral('/?e=user&' + url)

export const createDatabase = createAsyncThunk(
    'databases/create',
    async (payload: { name: string, template: number }, thunkApi) => {
        return await fetchFromDBApi('m=createdb&n=' + encodeURIComponent(payload.name) 
            + '&t=' + payload.template);
    }
)

export const archiveDatabase = createAsyncThunk(
    'databases/archive',
    async (payload: string, thunkApi) => await fetchFromDBApi('m=archive_db&id=' + encodeURIComponent(payload))
)

export const loadDatabases = createAsyncThunk(
    'databases/load',
    async (payload, thunkApi) => await fetchFromDBApi('m=dblist')
);

export const checkDatabaseCreationStatus = createAsyncThunk(
    'databases/checkCreationStatus',
    async (payload, thunkApi) => {
        const state = thunkApi.getState() as RootState
        return await fetchFromDBApi('m=checkstatus&id=' + encodeURIComponent(state.databases.databaseCreated!.uri))
    }
)

export const databasesSlice = createSlice({
    name: 'databases',
    initialState,
    reducers: {
        renameDatabase: (state, action: PayloadAction<{ code: string, name: string }>) => {
            state.list[action.payload.code].caption = action.payload.name;
            if(state.currentDatabase)
                state.currentDatabase.caption = action.payload.name;
        },
        setDatabaseCreationStatus: (state, action: PayloadAction<DatabaseCreationStatus>) => {
            state.databaseCreationStatus = action.payload;
        },
        resetAll: (state) => {
            state.list = {};
            state.templates = [];
            state.status = 'invalid';
            state.currentDatabase = undefined;
            state.currentDatabaseCode = undefined;
            state.databaseCreationStatus = 'idle';
        },
        setCurrentDatabase: (state, action) => {
            state.currentDatabaseCode = action.payload;
            state.currentDatabase = state.list[action.payload];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDatabases.pending, (state, action) => {
                state.status = 'loading';
                state.userUid = getAuthUserUid();
            })
            .addCase(loadDatabases.fulfilled, (state, action) => {
                state.list = action.payload.list;
                state.templates = action.payload.templates;
                state.status = 'idle';                
            })
            .addCase(loadDatabases.rejected, (state, action) => {
                console.log('database loading rejected',action);
                state.status = 'error';
                state.error = action.error.message;
            })

            .addCase(createDatabase.pending, (state, action) => {
                console.log('createDatabase pending', action);
                state.databaseCreationStatus = 'creating';
                state.list = {...state.list, _: {
                    caption: action.meta.arg.name,
                    status: 'creating',
                    api: '',
                    uri: ''
                }}
                state.databaseCreated = {
                    caption: action.meta.arg.name,
                    uri: '',
                    api: '',
                }
            })
            .addCase(createDatabase.fulfilled, (state, action) => {
                console.log('createDatabase fulfilled', action);
                state.databaseCreationStatus = 'idle';
                state.databaseCreated!.uri = action.payload.id;

                delete state.list['_'];
                state.list[action.payload.id] = action.payload.db;
            })
            .addCase(createDatabase.rejected, (state, action) => {
                console.log('createDatabase rejected', action);
                state.databaseCreationStatus = 'error';
                state.databaseCreationError = action.error.message;
                state.list._.status = 'error';
            })
            .addCase(checkDatabaseCreationStatus.pending, (state, action) => {})
            .addCase(checkDatabaseCreationStatus.fulfilled, (state, action) => {
                state.databaseCreationStatus = action.payload.status_name
                if(state.databaseCreationStatus === 'ready') {
                    state.status = 'invalid'
                }
            })
            .addCase(checkDatabaseCreationStatus.rejected, (state, action) => {
                state.databaseCreationStatus = 'error'
                state.databaseCreationError = action.error.message
            })

            .addCase(archiveDatabase.pending, (state, action) => {
                console.log('pending archiving', action)
            })
            .addCase(archiveDatabase.fulfilled, (state, action) => {
                console.log('fulfilled: ', action)
                state.currentDatabase!.status = 'archiving'
                state.list = action.payload.list
            })
            .addCase(archiveDatabase.rejected, (state, action) => {
                console.log('rejected: ', action)
                // TODO display error
                // state.databaseCreationStatus = 'error'
                // state.databaseCreationError = action.error.message
            })

        }
});

export const { renameDatabase, resetAll, setCurrentDatabase, setDatabaseCreationStatus } = databasesSlice.actions;

export const selectDatabaseCreationStatus = (state: RootState) => state.databases.databaseCreationStatus;
export const selectDatabases = (state: RootState) => state.databases.list;
export const selectDatabaseTemplates = (state: RootState) => state.databases.templates;
export const selectDatabasesStatus = (state: RootState) => state.databases.userUid === getAuthUserUid() ?  state.databases.status : 'invalid';
export const selectDatabasesError = (state: RootState) => state.databases.error;
export const selectCurrentDatabase = (state: RootState) => state.databases.currentDatabase;
export const selectCurrentDatabaseCode = (state: RootState) => state.databases.currentDatabaseCode;
export const selectDatabaseCreationError = (state: RootState) => state.databases.databaseCreationError;
export const selectDatabaseCreated = (state: RootState) => state.databases.databaseCreated;

export default databasesSlice.reducer;
