import React from 'react';
import { EditFlavor } from '../../../app/types';
import { Stack } from '@mui/material';
import DateEdit from './DateEdit';
import PeriodQuickSelector from './PeriodQuickSelector';

interface DateEditProps {
    docPath: string
    fieldFrom: string
    fieldUntil: string
    labelFrom?: string
    labelUntil?: string
    flavor?: EditFlavor
    autoFocus?: boolean
}

export default function PeriodEditWithQuickPicker(props: DateEditProps) {
    return <Stack direction="row" spacing={2} useFlexGap>
        <DateEdit {...props} field={props.fieldFrom} label={props.labelFrom} />
        <DateEdit {...props} field={props.fieldUntil} label={props.labelUntil} />
        <PeriodQuickSelector {...props} />  
    </Stack>
}
