import React from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { EditFlavor } from '../../../app/types';
import { useIntl } from 'react-intl';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useDataConnector } from '../../../redux/store';
import EnhancedFormControl from './EnhancedFormControl';
import { anyToType } from '../../../app/utils';
import InputSkeleton from '../InputSkeleton';
import { DEFAULT_DATE_FORMAT } from '../../../i18n/messages';

interface DateEditProps {
    docPath: string
    field: string
    label?: string
    flavor?: EditFlavor
    autoFocus?: boolean
}

export default function DateEdit({ docPath, field, label, flavor, autoFocus }: DateEditProps) {

    const dc = useDataConnector(docPath)
    const dispatch = useAppDispatch()
    const obj = dc.selectRecord(docPath)
    const error = dc.getValidationError(docPath, field)
    const isReady = dc.isRecordReadyForEdit(docPath)
    const isReadOnly = dc.isReadOnly(docPath)
    const val = dc.getValue(obj, field)
    const defaultValue = val === undefined ? null : dayjs(val)  //getDefaultValue()

    const handleChange = (newVal: any) => {
        
        // If new value is Dayjs, then format it.
        // If new value is string, then convert it to string.
        // Anyways, field must be updated with string value in YYYY-MM-DD format or undefined
        const val = (newVal !== null && newVal !== '') && ('object' === typeof newVal && !!newVal.isValid && newVal.isValid())
            ? newVal.format('YYYY-MM-DD')
            : typeof newVal === 'string' ? anyToType(newVal, 'date')
            : undefined;

        if (newVal !== defaultValue)
            dispatch(dc.updateFieldData({ docPath, field, val }));
    }

    const intl = useIntl();
    const labelMsg = label === undefined ? field : label;

    return <EnhancedFormControl
        docPath={docPath}
        field={field}
        flavor={flavor}
    >{
            isReady ? <DatePicker
                format={DEFAULT_DATE_FORMAT}
                label={labelMsg ? intl.formatMessage({ id: labelMsg }) : undefined}
                value={defaultValue}
                onChange={handleChange}
                disabled={isReadOnly}
                autoFocus={autoFocus}
                slotProps={{
                    textField: {
                        size: 'small',
                        variant: flavor === 'grid' ? 'standard' : 'outlined',
                        error: !!error,
                        helperText: !!error ? intl.formatMessage({ id: error }) : undefined
                    }
                }}
            /> : <InputSkeleton width={200} />
        }</EnhancedFormControl>
}