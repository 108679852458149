import { ApplicationUnit } from "../profit/regs";
import dayjs from "dayjs";
import { TextEditType } from "./types";
import { FormatDateOptions, IntlShape } from "react-intl";
import { ControlFormat } from "./types/types";

/**
 * Convert string to float, dot or comma will be treated as decimal separator.
 * NaN returned as 0
 * @param s string to convert
 * @returns number
 */
export function toFloat(s: any): number {
    const ret = Number.parseFloat(String(s).replace(',', '.').replace(' ', ''));
    return isNaN(ret) ? 0 : ret;
}

export function toInt(s: any): number {
    const ret = Number.parseInt(s)
    return isNaN(ret) ? 0 : ret;
}

export function anyToType(s: any, type?: TextEditType): string | number {
    switch (type) {
        case 'number':
            return toFloat(s);
        case 'int':
            return toInt(s);
        case 'date':
            return formatDateDB(strToDate(s));
        default:
            return s;
    }
}

/**
 * Convert Date to yyyy-mm-dd
 * @param d date to format
 * @returns 
 */
export function formatDateDB(d: Date): string {
    const [yr, dy, mt] = [d.getFullYear(), d.getDate(), 1 + d.getMonth()];
    return yr
        + '-' + (mt < 10 ? '0' : '') + mt
        + '-' + (dy < 10 ? '0' : '') + dy
}

/**
 * Converts date string to Date object
 * @param {string} s dd.mm.yyyy  or yyyy-mm-dd or mm/dd/yyyy
 * @returns {Date} converted date in case of correct format. If format could not be identified, will return current date
 */
export function strToDate(s: string): Date {
    const d = new Date()
    d.setUTCHours(0, 0, 0, 0)
    let delimiter: string = ''
    const delimiters = ['.', '-', '/'];
    for (let i = 0; i < delimiters.length; i++)
        if (s.indexOf(delimiters[i]) >= 0) {
            delimiter = delimiters[i];
            break;
        }
    try {
        let iD: number
        let iM: number
        let iY: number
        const dd = s.split(delimiter).map(i => Number.parseInt(i))
        if (dd.length != 3)
            throw new Error('Wrong count of fields in ' + s)
        switch (delimiter) {
            case '-':
                [iY, iM, iD] = dd
                break
            case '/':
                [iM, iD, iY] = dd
                break
            default: // actually '.'
                [iD, iM, iY] = dd
            // default:
            //     [iD, iM, iY] = [d.getDate(), d.getMonth(), d.getFullYear()]
        }

        d.setFullYear(iY);
        d.setMonth(iM - 1);
        d.setDate(iD);

        if (d.getFullYear() != iY) throw new Error('Year out of bounds in ' + s)
        if (d.getMonth() != iM - 1) throw new Error('Month out of bounds in ' + s)
        if (d.getDate() != iD) throw new Error('Date out of bounds in ' + s)

    } catch (e) {
        const def = new Date()
        def.setUTCHours(0, 0, 0, 0)
        return def
    }
    return d
}

export function round2(n: number): number {
    return Math.round(100 * n) / 100;
}

export const PATH_DELIMITER = "/";

export function extractDocChild(obj: object | undefined, path: string) {
    // console.log('extractDocChild: ', obj, path)
    try {
        if (obj === undefined)
            return undefined
        let s = obj as any
        path.split(PATH_DELIMITER).forEach((fld: string) => s = s[fld])
        return s
    }
    catch (e) {
        // console.log('extractDocChild error ', e, 'path="' + path + '": ', obj)
        return undefined
    }
}

export function getDocsLink(dbName: string, reg: ApplicationUnit, id: string = '') {
    return '/' + dbName + '/' + reg + '/' + id;
}

export function capitalizeFirstLetter(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * @brief add days to date
 * @param date string in yyyy-mm-dd format
 * @param days integer. Negative value also works
 * @returns string in yyyy-mm-dd format
 */
export function addDays(date: string, days: number): string {
    return dayjs(date).add(days, 'day').format('YYYY-MM-DD')
}

/**
 * @brieg get number of days between two dates in yyyy-mm-dd format
 * @param date1 
 * @param date2 
 */
export function getDaysBetweenDates(date1: string, date2: string): number {
    return dayjs(date2).diff(dayjs(date1), 'day')
}

export function formatValue(value: any, intl: IntlShape, format: ControlFormat): string {
    if (format !== undefined) {
        if ((format as FormatDateOptions).day !== undefined) // is date format 
            return value === undefined || value === null || value === '' ? '' : intl.formatDate(value, format);
        else
            return intl.formatNumber(value, format);
    }

    return value;
}

export function today(): string {
    return dayjs().format('YYYY-MM-DD');
}

export function this_month_start(): string {
    const today = new Date();
    return '' + today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-01';
}

export function this_month_end(): string {
    const today = new Date();
    const nmt = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return nmt.getFullYear() + '-' + (nmt.getMonth() + 1).toString().padStart(2, '0') + '-' + nmt.getDate().toString().padStart(2, '0');
}

export function last_month_start(): string {
    const today = new Date();
    const lms = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    return '' + lms.getFullYear() + '-' + (lms.getMonth() + 1).toString().padStart(2, '0') + '-01';
}

export function last_month_end(): string {
    const today = new Date();
    const lme = new Date(today.getFullYear(), today.getMonth(), 0);
    return lme.getFullYear() + '-' + (lme.getMonth() + 1).toString() + '-' + lme.getDate().toString();
}

export function this_year_start(): string {
    const today = new Date();
    return '' + today.getFullYear() + '-01-01';
}

export function this_year_end(): string {
    const today = new Date();
    return '' + today.getFullYear() + '-12-31';
}

export function last_year_start(): string {
    const today = new Date();
    return '' + (today.getFullYear() - 1) + '-01-01';
}

export function last_year_end(): string {
    const today = new Date();
    return '' + (today.getFullYear() - 1) + '-12-31';
}

export function prior_year_start(): string {
    const today = new Date();
    return '' + (today.getFullYear() - 2) + '-01-01';
}

export function prior_year_end(): string {
    const today = new Date();
    return '' + (today.getFullYear() - 2) + '-12-31';
}

/**
 * modifiers for umlauts in file names.
 */
const umlModifiers: { [n: number]: { [k: string]: string } } = {
    776: {
        'a': 'ä',
        'o': 'ö',
        'u': 'ü',
        'A': 'Ä',
        'O': 'Ö',
        'U': 'Ü',
    },
    780: {
        'z': 'ž',
        's': 'š',
        'Z': 'Ž',
        'S': 'Š',
    },
    771: {
        'o': 'õ',
        'O': 'Õ',
    },
}

// function replaceUmlaut(s: string, modifier: number) {
//     const ret = umlModifiers[modifier][s];
//     console.log('replacing ', s, ' with ', ret);
//     return ret;
// }

/**
 * Replace umlauts in file name
 * @param fn file name
 * @returns fixed file name
 */
export function fixFilename(fn: string) {

    let ret: string[] = [];
    fn.split('').forEach((c: string) => {
        const cc = c.charCodeAt(0);
        // console.log('checking ', c, ' with ', cc);
        if (!!umlModifiers[cc]) {
            if (ret.length > 0)
                ret[ret.length - 1] = umlModifiers[cc][ret[ret.length - 1]]; //replaceUmlaut(ret[ret.length - 1], cc);
            else
                console.log('fixFilename: umlaut without previous character');
        } else
            ret.push(c);
        // ret.push(replaceUmlaut(ret.pop(), cc));
    });

    // replace all characters in rr that are not a-z, A-Z, 0-9, _ or - with _
    const rr = ret.join('').replace(/[^a-zA-Z0-9_.-]/g, '_');

    // console.log('from: ', fn.split('').map((c: string) => ' ' + c + '=' + c.charCodeAt(0).toString(16).toUpperCase()).join(' '));
    // console.log('to:   ', rr.split('').map((c: string) => ' ' + c + '=' + c.charCodeAt(0).toString(16).toUpperCase()).join(' '));

    return rr;
}