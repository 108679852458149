import React from "react";
import { ReportDescriptor, ReportOutputKind } from "../../../../app/types/ReportDescriptor";
import { ApplicationUnit } from "../../../regs";
import { ParamTypesDescriptors, ReportDataDescriptor, ReportParams, TabularReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";
import { DocGridColumn } from "../../../../app/types";

const Form = React.lazy(() => import('./ProfitReportForm'));

const paramTypes: ParamTypesDescriptors = {
    'period1_start': { type: 'date' },
    'period1_end': { type: 'date' },
    'period2_start': { type: 'date' },
    'period2_end': { type: 'date' },
    'period3_start': { type: 'date' },
    'period3_end': { type: 'date' },
    'period4_start': { type: 'date' },
    'period4_end': { type: 'date' },
    'currency_id': { type: 'select', selectorRd: 'currencies', title: 'currency' },
    'object_id': { type: 'select', selectorRd: 'objects', title: 'object' },
};

class ProfitReportRD extends ReportDescriptor {

    reportEndpoint: string = 'profit_report';
    name: ApplicationUnit = 'profit_report';

    public getReportForm = (docPath: string) => <Form docPath={docPath} />

    public getOutputKinds(): ReportOutputKind[] {
        return ['table', 'pdf'];   //'pdf', 'json', 
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        console.log('cols for ', params);

        const ret: DocGridColumn[] = [
            { name: 'name', width: 300, label: 'name' },
        ];

        for (let i = 1; i <= 4; i++) {
            if(params['period' + i + '_start']) {
                ret.push(
                    { 
                        name: 'v' + i, 
                        type: 'decimal', 
                        width: 100, 
                        align: 'right', 
                        label: 'period_' + i //params['period' + i + '_start'] + ' - ' + params['period' + i + '_end']
                    },
                );
            }
        }


        return ret;

    }

    public async loadReportData(params: any): Promise<TabularReportDataDescriptor> {

        const data = await this.prepareReportData(this.getReportParams(params), paramTypes);
        const output = data.output.dataset;

        // console.table(output);

        // filter with print conditions
        // 0: always
        // 1: not null
        // 2: > 0
        // 3: < 0
        // 4: never

        const o2: any[] = [];
        output.forEach((row: any) => {

            if(row.style == 'bold')
                row._styles = { fontStyle: 'bold' };

            switch (row.print_condition) {
                case 0: // always
                    o2.push(row);
                    break;
                case 1: // not null
                    if (row.v1 || row.v2 || row.v3 || row.v4)
                        o2.push(row);
                    break;
                case 2: // > 0
                    if (row.v1 > 0 || row.v2 > 0 || row.v3 > 0 || row.v4 > 0)
                        o2.push(row);
                    break;
                case 3: // < 0
                    if (row.v1 < 0 || row.v2 < 0 || row.v3 < 0 || row.v4 < 0)
                        o2.push(row);
                    break;
                case 4: // never
                    break;
            }
        });


        // console.table(o2);


        return {
            ...data,
            output: o2,
            title: data.output.metadata.name
        }
    }

    public getReportFormModule = async (reportData: ReportDataDescriptor) =>
        await import('./ProfitReportPrintForm');
}

export const profit_report = new ProfitReportRD();