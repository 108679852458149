import React from 'react'
import { RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from '../../../regs';

class MyUserDataRD extends RegistryDescriptor {
    docEndpoint = 'my_user_data'
    name: ApplicationUnit = 'my_user_data'
    defaultDocument = {}
}

export const my_user_data = new MyUserDataRD()
