import React from "react";
import { Box, IconButton, List, ListItemButton, Menu, SvgIconTypeMap, Tooltip } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { SidebarItem } from "../../../ui/components/sidebar";
import { menuProps2 } from "../../../ui/themes/styles";
import { useDataConnector } from "../../../redux/store";
import { useAppDispatch } from "../../../app/hooks";
import { today, last_month_end, last_year_end, prior_year_end } from "../../../app/utils";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { OverridableComponent } from "@mui/material/OverridableComponent";

const DropDownSelectorButton = ({ icon, tooltip, items, handler }: {
    icon: any,  //TODO ? OverridableComponent<SvgIconTypeMap<{}, "svg">>, 
    tooltip?: string,
    items: { caption: string, get: () => any }[],
    handler: (newVal: any) => void
}) => {

    const intl = useIntl();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <Tooltip title={intl.formatMessage({ id: tooltip || 'settings' })}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'settings' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {icon}
                </IconButton>
            </Box>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            id="settings-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={menuProps2}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <List component='div' disablePadding dense>
                {items.map((item, index) => <SidebarItem key={index}>
                    <ListItemButton onClick={() => {
                        handler(item.get());
                    }}>
                        <FormattedMessage id={item.caption} />
                    </ListItemButton>
                </SidebarItem>)}
            </List>
        </Menu>
    </>
}

export default DropDownSelectorButton;