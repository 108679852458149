import { DatabaseDescriptor, DocGridColumn, KeyValueList } from "."
import { LanguageType } from "../../i18n/messages"
import { ApplicationUnit } from "../../profit/regs"
import { SelectEditItem } from "../../ui/components/edit/SelectEdit"

export type ApplicationUnitType = 'registry' | 'report' | 'settings' | undefined

export interface SettingsSidebarItem {
    id: string
    caption?: string
    default?: boolean
    visible?: boolean
}

export type ReportParams = {[key: string]: any};
export type ReportParamType = 'text' | 'date' | 'number' | 'select' | 'options' | 'checkbox';
export interface ReportParam {
    type: ReportParamType
    selectorRd?: string
}

export interface ParamTypeDescriptor {
    type: ReportParamType,
    selectorRd?: ApplicationUnit, 
    title?: string,
    items?: SelectEditItem[]
}

export type ParamTypesDescriptors = {[key: string]: ParamTypeDescriptor}

export interface TranslatedReportParams {
    key: string
    title: string
    value: any
    translatedValue?: string
    type: ReportParamType
}

export interface ReportDataDescriptor {
    doc?: any,
    db?: DatabaseDescriptor,
    setup?: KeyValueList,
    locale?: LanguageType,
    messages?: any,
    email_to?: string,      // default e-mail recipient for the report
    report_title?: string
}

export interface TabularReportDataDescriptor extends ReportDataDescriptor {
    title?: string,
    input: TranslatedReportParams[],
    inputRaw: ReportParams,
    output: any
}

export class ApplicationUnitDescriptor {
    type: ApplicationUnitType
    name: ApplicationUnit

    file_drop_enabled: boolean = false

    public getTitle(doc: any): string {
        return this.name || this.type || 'unknown';
    }

    public async loadReportData(params: any): Promise<ReportDataDescriptor> {
        throw new Error('loadReportData is not implemented in ' + this.name);
    }

    public async getReportFormModule(reportData : ReportDataDescriptor): Promise<any> {
        console.warn('getReportFormModule is not implemented in ApplicationUnitDescriptor');
        return {}
    }

    /**
     * Sometimes we need to process data before it is presented in the tabular view.
     * It could be used for example to flatten nested data structures.
     * @param data report data
     * @returns processed data for tabular report presentation
     */
    public processTabularData(data: any): any {
        return data;
    }

    public getTabularReportColumns(params: ReportParams): DocGridColumn[] {
        return [];
    }

    public getSettingsSidebarItems(): SettingsSidebarItem[] {
        return []
    }

    public get_drop_files_endpoint(file: File): string {
        return ''
    }
}
