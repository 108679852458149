import { configureStore, ThunkAction, Action, Middleware } from '@reduxjs/toolkit';
import { getRegDescriptor, getRegDescriptorByPath } from '../profit';
import authReducer from './auth/authSlice'  //{login, logout}
import docsReducer, { selectRecord, selectRecordStatus, updateFieldData } from './docs/docsSlice'
import dialogsReducer from './dialogs/dialogsSlice';
import selectorsReducer from './selectors/selectorsSlice';
import localSetupReducer, { selectLocalSetupValue, setLocalSetupItem, setLocalSetupSubValue } from './localSetup/localSetupSlice';
import gridsReducer from './grids/gridsSlice';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { addApiErrorListener } from '../profit/api';
import { SETUP_PREFIX_GRID, SETUP_SUFFIX_SORTED } from '../app/const';
import reportsReducer from './reports/reportsSlice';
import databasesReducer from './databases/databasesSlice';
import datasetsReducer from './datasets/datasetsSlice';
import sharedSetupReducer, { selectSharedSetup, selectSharedSetupStatus, setSharedSetupValue } from './sharedSetup/sharedSetupSlice'
import selectorDialogsReducer from './selectorDialogs/selectorDialogsSlice'
import { splitDocPath } from './docs/utils';
import docLinksSlice from './docLinks/docLinksSlice';
import { AbstractDataConnector, UpdateFieldDataPayload } from '../app/types/DataConnector';
import { extractDocChild } from '../app/utils';
import userManagementSlice from './userManagement/userManagementSlice';
import userProfileSlice from './userProfile/userProfileSlice';
import userSetupSlice, { selectUserSetup, selectUserSetupStatus, selectUserSetupValueAsObject, setSecureUserSetupValue, setUserSetupObjectValue, setUserSetupObjectValueNoUpload, setUserSetupValue } from './userSetup/userSetupSlice';
import privilegesSlice from './privileges/privilegesSlice';
import profitCentralDatasetsReducer from './profitCentralDatasets/profitCentralDatasetsSlice';
import { saveEnterpriseName } from '../profit/profitcentral';

const persistConfigAuth = {
    key: 'auth',
    blacklist: [
        'status', 'loginForm.password'
    ],
    storage
}

const persistConfigGrids = {
    key: 'grids',
    whitelist: ['setup'],
    storage
}


const customMiddleware: Middleware = store => next => action => {

    // console.log('MW: ', action)

    if (action.type === 'docs/saveRecord/fulfilled' || action.type === 'docs/doDeleteRecord/fulfilled') {
        const { dbName, regName, id } = splitDocPath(action.meta.arg)
        const rd = getRegDescriptor(regName)
        store.dispatch({ type: 'grids/resetGrid', payload: dbName + '/' + rd.gridEndpoint })
    }


    // reset selector if record changed
    // TODO better reset/update on existing record change
    // TODO mind dependencies
    if (action.type === 'docs/saveRecord/fulfilled') {
        const docPath = action.meta.arg;
        const { dbName, regName, id } = splitDocPath(docPath);
        const selectorName = dbName + '/' + regName;
        // if (id === 'new') {
        console.log('resetting selector ' + selectorName);
        store.dispatch({ type: 'selectors/resetSelector', payload: selectorName });
        // }
    }

    // reset doclinks after doc save/lock/unlock
    // TODO if unlock, action.meta.arg is object, should be string
    if (action.type.startsWith('docs/')) {
        const pp = action.type.split('/')
        if ((pp[1] === 'saveRecord' || pp[1] === 'lockRecord' || pp[1] === 'unlockRecord')
            && (pp[2] === 'fulfilled' || pp[2] === 'rejected')) {
            const docPath = typeof action.meta.arg === 'string' ? action.meta.arg : action.meta.arg.docPath
            // console.log('resetting docLinks for ', docPath)
            store.dispatch({ type: 'docLinks/reset', payload: docPath })
        }
    }

    // Reload grid if sorted column changed
    if (action.type === setLocalSetupItem.type) {
        const [prefix, value, suffix] = action.payload.k.split('.')
        if (prefix === SETUP_PREFIX_GRID && suffix === SETUP_SUFFIX_SORTED)
            store.dispatch({ type: 'grids/resetGrid', payload: value })
        // TODO add locally instead of reload fully?
        // TODO reset/update on existing record change
    }

    if (action.type === 'databases/create/fulfilled') {
        store.dispatch({ type: 'userProfile/resetProfile' });
    }

    if (action.type === "sharedSetup/setSharedSetupValue/fulfilled" && action.payload.data[0].updated_name === 'FIRMA.NAME') {
        const newCompanyName = action.payload.data[0].updated_value;
        // console.log('FIRMA.NAME changed, save to central: ', newCompanyName);
        const docPath = action.meta?.arg.docPath;
        if (docPath) {
            const sdp = splitDocPath(docPath);
            saveEnterpriseName(sdp.dbName, newCompanyName);
            store.dispatch({ type: 'databases/renameDatabase', payload: {code: sdp.dbName, name: newCompanyName}});
        }
    }

    if (action.type === 'docs/executeAndReload/fulfilled' || action.type === 'docs/executeAndReload/rejected')
        store.dispatch({ type: 'docLinks/reset', payload: action.meta.arg.docPath });


    if (action.type === 'docLinks/uploadFile/fulfilled' || action.type === 'docLinks/uploadFile/rejected') {
        console.log('uploadFile/fulfilled/rejected', action);
        const db = action.meta.arg.docPath.split('/')[0];
        store.dispatch({ type: 'grids/resetGrid', payload: db + '/grid_purchaseinvoices' });
        store.dispatch({ type: 'selectors/resetSelector', payload: db + '/companies' });
    }

    // Pass the action next
    return next(action);
};


export const store = configureStore({
    reducer: {
        auth: authReducer, // persistReducer<any, any>(persistConfigAuth, authReducer),     //TODO dirty hack see https://github.com/rt2zz/redux-persist/issues/1140
        localSetup: persistReducer<any, any>({ key: 'setup', storage }, localSetupReducer),
        databases: databasesReducer,
        grids: persistReducer<any, any>(persistConfigGrids, gridsReducer),
        dialogs: dialogsReducer,
        selectors: selectorsReducer,
        datasets: datasetsReducer,
        profitCentralDatasets: profitCentralDatasetsReducer,
        selectorDialogs: selectorDialogsReducer,
        sharedSetup: sharedSetupReducer,
        userSetup: userSetupSlice,
        docs: docsReducer,
        reports: reportsReducer,
        docLinks: docLinksSlice,
        userManagement: userManagementSlice,
        userProfile: userProfileSlice,
        privileges: privilegesSlice
        // lang: persistReducer<any, any>(persistConfig, langReducer),
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] },
    }).concat(customMiddleware)
});

addApiErrorListener(msg => {
    console.log('api error: ', msg)
    // if(msg === 'Unauthenticated')
    //     store.dispatch(logout())
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

export class DocumentDataConnector extends AbstractDataConnector {

    public isForDocPath = (docPath: string) => true;
    public selectRecord = selectRecord;
    public updateFieldData = updateFieldData;

    public isRecordReadyForEdit(docPath: string): boolean {
        const status = selectRecordStatus(docPath)
        return status !== 'invalid' && status !== 'loading'
    }
    public getValue = (obj: any, field: string) => extractDocChild(obj, field);

    public isReadOnly(docPath: string): boolean {
        // TODO is it optimal?
        return getRegDescriptorByPath(docPath).isReadOnly(selectRecord(docPath))
    }

    public getValidationError(docPath: string, fieldPath: string): string | undefined {
        const doc = selectRecord(docPath)
        return doc?.validationErrors?.[fieldPath]
    }
}

export class SetupDataConnector extends AbstractDataConnector {

    public isForDocPath(docPath: string): boolean {
        return docPath.endsWith('/setup') || docPath.includes('/setup/')
    }

    public selectRecord(docPath: string) {
        const parts = docPath.split('/')
        return selectSharedSetup(parts[0])
    }
    public updateFieldData(payload: UpdateFieldDataPayload) {
        return setSharedSetupValue(payload)
    }
    public isRecordReadyForEdit(docPath: string): boolean {
        // return true
        const parts = docPath.split('/')
        const status = selectSharedSetupStatus(parts[0])
        return status !== 'invalid' && status !== 'loading'
    }

    public isReadOnly(docPath: string): boolean {
        return false;
    }

    public getValue(obj: any, field: string): any {
        return obj[field]
    }
    public getValidationError = (docPath: string, fieldPath: string): string | undefined => undefined
}

export class UserSetupDataConnector extends AbstractDataConnector {

    public isForDocPath(docPath: string): boolean {
        return docPath.endsWith('/userSetup') || docPath.includes('/userSetup/')
    }

    public selectRecord(docPath: string) {
        const parts = docPath.split('/');
        return selectUserSetup(parts[0]);
    }
    public updateFieldData(payload: UpdateFieldDataPayload) {
        return setUserSetupValue(payload);
    }
    public isRecordReadyForEdit(docPath: string): boolean {
        const parts = docPath.split('/');
        const status = selectUserSetupStatus(parts[0]);
        return status !== 'invalid' && status !== 'loading';
    }

    public isReadOnly(docPath: string): boolean {
        return false;
    }

    public getValue(obj: any, field: string): any {
        return obj[field]
    }
    public getValidationError = (docPath: string, fieldPath: string): string | undefined => undefined
}

export class SecureUserSetupDataConnector extends UserSetupDataConnector {
    public isForDocPath(docPath: string): boolean {
        return docPath.endsWith('/secureUserSetup') || docPath.includes('/secureUserSetup/')
    }
    public updateFieldData(payload: UpdateFieldDataPayload) {
        return setSecureUserSetupValue(payload)
    }
}


export class LocalSetupDataConnector extends AbstractDataConnector {
    public isForDocPath = (docPath: string): boolean =>
        docPath.includes('/report/') || docPath.includes('/localSetup/')
    public selectRecord = (docPath: string) => {
        const ret = selectLocalSetupValue(docPath);
        // console.log('selectRecord', docPath, ' --> ', ret);
        return ret;
    }
    public updateFieldData = (payload: UpdateFieldDataPayload) => setLocalSetupSubValue(payload)
    public isRecordReadyForEdit = (docPath: string): boolean => true
    public isReadOnly = (docPath: string): boolean => false
    public getValue = (obj: any, field: string): any => obj ? obj[field] : undefined
    public getValidationError = (docPath: string, fieldPath: string): string | undefined => undefined
}

abstract class AbstractUserSetupVariableDataConnector extends AbstractDataConnector {
    public selectRecord(docPath: string) {
        const p = splitDocPath(docPath);
        return selectUserSetupValueAsObject(p.dbName, p.id);
    }

    public isRecordReadyForEdit(docPath: string): boolean {
        const parts = docPath.split('/')
        const status = selectUserSetupStatus(parts[0])
        return status !== 'invalid' && status !== 'loading'
    }

    public isReadOnly = (docPath: string): boolean => false
    public getValue = (obj: any, field: string): any => obj[field]
    public getValidationError = (docPath: string, fieldPath: string): string | undefined => undefined
}

export class UserSetupVariableDataConnector extends AbstractUserSetupVariableDataConnector {

    public static readonly DOC_PATH_IDENTIFIER = 'userSetupVar'

    public isForDocPath(docPath: string): boolean {
        return docPath.endsWith('/' + UserSetupVariableDataConnector.DOC_PATH_IDENTIFIER)
            || docPath.includes('/' + UserSetupVariableDataConnector.DOC_PATH_IDENTIFIER + '/')
    }

    public updateFieldData = (payload: UpdateFieldDataPayload) => setUserSetupObjectValue(payload);
}

export class UserSetupVariableDataConnectorLocallySaved extends AbstractUserSetupVariableDataConnector {

    public static readonly DOC_PATH_IDENTIFIER = 'userSetupVarLocallySaved'

    public isForDocPath(docPath: string): boolean {
        return docPath.endsWith('/' + UserSetupVariableDataConnectorLocallySaved.DOC_PATH_IDENTIFIER)
            || docPath.includes('/' + UserSetupVariableDataConnectorLocallySaved.DOC_PATH_IDENTIFIER + '/')
    }

    public updateFieldData = (payload: UpdateFieldDataPayload) => setUserSetupObjectValueNoUpload(payload)
}

const documentDC = new DocumentDataConnector();
const setupDC = new SetupDataConnector();
const localSetupDC = new LocalSetupDataConnector();
const userSetupDC = new UserSetupDataConnector();
const secureUserSetupDC = new SecureUserSetupDataConnector();
const userSetupVarDC = new UserSetupVariableDataConnector();
const userSetupVarLocallySavedDC = new UserSetupVariableDataConnectorLocallySaved();

export function useDataConnector(docPath: string): AbstractDataConnector {

    if (userSetupVarDC.isForDocPath(docPath))
        return userSetupVarDC;
    if (userSetupDC.isForDocPath(docPath))
        return userSetupDC;
    if (secureUserSetupDC.isForDocPath(docPath))
        return secureUserSetupDC;
    if (setupDC.isForDocPath(docPath))
        return setupDC;
    if (localSetupDC.isForDocPath(docPath))
        return localSetupDC;
    if (userSetupVarLocallySavedDC.isForDocPath(docPath))
        return userSetupVarLocallySavedDC;

    return documentDC;
}
