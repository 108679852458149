import React from "react";
import { DocPathParams } from "../../../../app/types";
import ComboEdit from "../../../../ui/components/edit/ComboEdit";
import { Stack } from "@mui/material";
import PeriodEditWithQuickPicker from "../../../../ui/components/edit/PeriodEditWithQuickPicker";
import ClearableRangeEdit from "../../../../ui/components/edit/ClearableRangeEdit";

export default function WhInitsFilterForm(params: DocPathParams) {
    return <Stack>
        <PeriodEditWithQuickPicker {...params} fieldFrom='doc_date_from' fieldUntil='doc_date_until' />
        <ClearableRangeEdit {...params} fieldFrom='total_from' fieldUntil='total_until' />
        <ComboEdit {...params} field='dst_wh_id' reg='warehouses' />
        <ComboEdit {...params} field='article_id' reg='articles' />
    </Stack>
}