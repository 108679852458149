import React from 'react'
import { DocGridColumn, RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from '../../../regs';

const EditForm = React.lazy(() => import('./UserEdit'))

class UsersRD extends RegistryDescriptor {
    gridEndpoint = 'users_grid'
    docEndpoint = 'users'
    selector = {
        endpoint: 'users',
        idCol: 'id',
        captionCol: 'login',
        textCol: 'login',
        // getOptionLabel: (option: SelectorListItem) => option.caption,
        queryParams: {_orderBy: 'login'},
    }
    name: ApplicationUnit = 'users'
    columns: DocGridColumn[] =  [
        {name: 'login', width: 100},
        {name: 'first_name', width: 200},
        {name: 'last_name', width: 200},
        {name: 'occupation_name', width: 200},
        {name: 'email', width: 200},
    ]
    defaultDocument = {}
    getDetailForm = (docPath: string) => <EditForm docPath={docPath} />
}

export const users = new UsersRD()
