import React from "react";
import { useDataConnector } from "../../../redux/store";
import { useAppDispatch } from "../../../app/hooks";
import { today, last_month_end, last_year_end, prior_year_end, this_month_start, this_month_end, last_month_start, last_year_start, this_year_start, this_year_end, prior_year_start } from "../../../app/utils";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DropDownSelectorButton from "./DropDownSelectorButton";

interface DateQuickSelectorProps {
    docPath: string
    fieldFrom: string
    fieldUntil: string
    tooltip?: string
}

const PeriodQuickSelector = ({ docPath, fieldFrom, fieldUntil, tooltip }: DateQuickSelectorProps) => {

    const dc = useDataConnector(docPath);
    const dispatch = useAppDispatch();
    const obj = dc.selectRecord(docPath);
    const valFrom = dc.getValue(obj, fieldFrom);
    const valUntil = dc.getValue(obj, fieldUntil);

    const items = [
        {
            caption: 'period_quick_selector_clear',
            get: () => ({ from: undefined, until: undefined })
        },
        {
            caption: 'period_quick_selector_today',
            get: () => ({ from: today(), until: today() })
        },
        {
            caption: 'period_quick_selector_this_month',
            get: () => ({ from: this_month_start(), until: this_month_end() })
        },
        {
            caption: 'period_quick_selector_last_month',
            get: () => ({ from: last_month_start(), until: last_month_end() })
        },
        {
            caption: 'period_quick_selector_last_month_until_today',
            get: () => ({ from: last_month_start(), until: today() })
        },
        {
            caption: 'period_quick_selector_this_year',
            get: () => ({ from: this_year_start(), until: this_year_end() })
        },
        {
            caption: 'period_quick_selector_last_year',
            get: () => ({ from: last_year_start(), until: last_year_end() })
        },
        {
            caption: 'period_quick_selector_prior_year',
            get: () => ({ from: prior_year_start(), until: prior_year_end() })
        },
    ];

    const handleItem = (newVal: any) => {
        // console.log('PeriodQuickSelector.handleItem', newVal);
        if (newVal.from !== valFrom)
            dispatch(dc.updateFieldData({ docPath, field: fieldFrom, val: newVal.from }))
        if (newVal.until !== valUntil)
            dispatch(dc.updateFieldData({ docPath, field: fieldUntil, val: newVal.until }))
    }

    return <DropDownSelectorButton
        icon={<MoreHorizIcon />}
        tooltip={tooltip} items={items}
        handler={handleItem}
    />
}

export default PeriodQuickSelector;