import React from "react";
import { Autocomplete, Box, Popper, TextField, Typography, createFilterOptions } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { loadSelector, SelectorListItem, selectRegSelectorItems, selectRegSelectorStatus } from "../../../redux/selectors/selectorsSlice";
import { EditFlavor } from "../../../app/types";
import { useIntl } from "react-intl";
import { getRegDescriptor } from "../../../profit";
import { selectCurrentDatabase } from "../../../redux/databases/databasesSlice";
import { constructDisplayedDocPath, pushDialog } from "../../../redux/selectorDialogs/selectorDialogsSlice";
import { useDataConnector } from "../../../redux/store";
import EnhancedFormControl from "./EnhancedFormControl";
import { ApplicationUnit } from "../../../profit/regs";
import { anyToType } from "../../../app/utils";
import InputSkeleton from "../InputSkeleton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface ComboEditProps {
    docPath: string
    field: string
    label?: string
    reg: ApplicationUnit
    flavor?: EditFlavor
    popperWidth?: number | string
    addNewPossible?: boolean
    readonly?: boolean
    nullValue?: number | string | null
    autoFocus?: boolean
}

export default function ComboEdit({ docPath, field, label, reg, flavor, popperWidth, addNewPossible, readonly, nullValue, autoFocus }: ComboEditProps) {

    const dc = useDataConnector(docPath);
    const dispatch = useAppDispatch();
    const recordIsReady = dc.isRecordReadyForEdit(docPath);
    const db = useAppSelector(selectCurrentDatabase);
    const fullSelectorPath = db!.uri + '/' + reg;
    const selectorStatus = selectRegSelectorStatus(fullSelectorPath);
    const comboIsNotReady = !recordIsReady
        || selectorStatus === 'invalid' || selectorStatus === 'loading';

    React.useEffect(() => {
        if (selectorStatus === 'invalid')
            dispatch(loadSelector(fullSelectorPath));
    }, [selectorStatus, fullSelectorPath]);

    // console.log('RENDER ComboEdit', docPath, 'from ', reg, 'nv=', nullValue,
    //     recordIsReady ? 'ready' : 'not ready',
    //     'selector:', selectorStatus);

    return <EnhancedFormControl
        docPath={docPath}
        field={field}
        flavor={flavor}
        fullWidth
    >{
            comboIsNotReady ?
                <InputSkeleton /> :

                <InnerAutocomplete
                    docPath={docPath}
                    field={field}
                    label={label}
                    reg={reg}
                    flavor={flavor}
                    popperWidth={popperWidth}
                    addNewPossible={addNewPossible}
                    readonly={readonly}
                    nullValue={nullValue}
                    autoFocus={autoFocus}
                />

        }</EnhancedFormControl>
}

const InnerAutocomplete = function ({ docPath, field, label, reg, flavor, popperWidth, addNewPossible, readonly, nullValue, autoFocus }: ComboEditProps) {

    const dc = useDataConnector(docPath)
    const dispatch = useAppDispatch()
    // const recordIsReady = dc.isRecordReadyForEdit(docPath)
    const obj = dc.selectRecord(docPath)
    const error = dc.getValidationError(docPath, field)
    const rd = getRegDescriptor(reg)
    const getOptionLabel = rd.selector.getOptionLabel ?
        rd.selector.getOptionLabel :
        (option: SelectorListItem) => option.caption + ' : ' + option.text //+ ' #' + option.id

    const db = useAppSelector(selectCurrentDatabase)!;

    const fullSelectorPath = db.uri + '/' + reg

    const list = selectRegSelectorItems(fullSelectorPath);
    const selectorStatus = selectRegSelectorStatus(fullSelectorPath);
    // const comboIsNotReady = !recordIsReady
    //     || selectorStatus === 'invalid' || selectorStatus === 'loading';

    const getDefaultValue = () => {
        const r = anyToType(dc.getValue(obj, field), rd.selector.idColType || 'int');
        return r ? list.find(item => item.id === r) : null;
    }
    const defaultValue = getDefaultValue();

    React.useEffect(() => {
    }, [defaultValue])

    React.useEffect(() => {
        if (selectorStatus === 'invalid')
            dispatch(loadSelector(fullSelectorPath))
    }, [selectorStatus, fullSelectorPath])

    const getNullValue = () => {
        if (nullValue !== undefined) return nullValue;
        if (rd.selector.nullValue !== undefined) return rd.selector.nullValue;
        return (rd.selector.idColType === 'string' ? '' : 0);
    }

    const handleChange = (event: any, newValue: SelectorListItem | null) => {
        // console.log('ComboEdit.handleChange', { docPath, field, event, newValue });
        if (newValue && typeof (newValue.id) === 'number' && newValue.id < 1) {
            // TODO what if newValue.id is string?
            // TODO only if addNewPossible and addNew method is "explicit dialog"
            dispatch(pushDialog({
                callback: {
                    docPath: docPath,
                    field: field,
                },
                selectorReg: reg,
                title: 'new_' + reg + '_title',
                defaultValue: 'new',
                sharedSetup: {
                    __addNew_code: newValue.caption.toUpperCase().trim(), // TODO is it always upercase?
                    __addNew_name: newValue.caption,
                },
                docPath: constructDisplayedDocPath(db.uri, obj, field, reg, 'new')
            }))
        } else if (newValue !== defaultValue) {
            dispatch(dc.updateFieldData({
                docPath: docPath,
                field: field,
                val: newValue?.id || getNullValue()
            }));
        }
    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} style={{ width: popperWidth }} placement='bottom-start' />)
    }

    const renderOption = (props: any, option: any) => <Box 
        component="li" 
        sx={{ mr: 2, flexShrink: 0, flexGrow: 1 }}
        {...props}     
    >
        {option.id ? null : <AddCircleOutlineIcon sx={{m: 0, mr: 8}}/>}
        {getOptionLabel(option)}
    </Box>

    const filter = createFilterOptions<SelectorListItem>();

    const intl = useIntl()
    const labelMsg = flavor === 'grid' ? '' : !!field ? intl.formatMessage({ id: (label || field) }) : ''
    const labelAddNew = intl.formatMessage({ id: 'not_found_add' })
    const isReadOnly = dc.isReadOnly(docPath)

    const renderInput = (params: any) => <TextField
        variant={flavor === 'grid' ? 'standard' : 'outlined'}
        {...params}
        label={labelMsg}
        error={!!error}
        autoFocus={autoFocus}
        helperText={!!error ? intl.formatMessage({ id: error }) : undefined}
    />


    // console.log('RENDER INNER ComboEdit', docPath, 'from ', reg,
    //     // 'nv=', nullValue,
    //     error || '<no error>',
    //     // recordIsReady ? 'ready' : 'not ready',
    //     'options: ', list.length,
    //     // 'status:', selectorStatus
    // );

    return readonly || isReadOnly ?
        (
            flavor === 'grid' ?
                <Typography>{defaultValue ? getOptionLabel(defaultValue) : ''}</Typography>
                :
                <TextField
                    variant='outlined'
                    value={defaultValue ? getOptionLabel(defaultValue) : ''}
                    disabled size="small"
                    autoFocus={autoFocus}
                    label={labelMsg}
                />
        ) :

        <Autocomplete size="small"
            PopperComponent={popperWidth ? PopperMy : undefined}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={defaultValue || null}
            onChange={handleChange}
            id={field}
            getOptionLabel={getOptionLabel}
            options={list}
            renderOption={renderOption}
            renderInput={renderInput}
            selectOnFocus
            clearOnBlur
            filterOptions={addNewPossible ? (options: Array<SelectorListItem>, params: { inputValue: string, getOptionLabel: any }) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === getOptionLabel(option));
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        id: 0,
                        caption: inputValue,
                        text: labelAddNew + ' "' + inputValue + '"',
                    });
                }
                return filtered;
            } : undefined}
        />
}