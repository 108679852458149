import { PayloadAction, SerializedError, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState, store } from '../store'
import { get } from '../../profit/api';
import { LoadableItemStatus, KeyValueList } from '../../app/types';
import { UpdateFieldDataPayload } from '../../app/types/DataConnector';
import { useSelector } from 'react-redux';
import { splitDocPath } from '../docs/utils';
import { createIdSelector, createSelector } from 'redux-views';

export interface UserSetup {
    setup: KeyValueList
    status: LoadableItemStatus
    lastError?: SerializedError
}

interface UserSetups { [db: string]: UserSetup }

export interface UserSetupState { setups: UserSetups }

const initialState: UserSetupState = { setups: {} }

export const getUserSetupValue = async (key: string) => {
    const state = store.getState() as RootState
    return await getUserSetupValue2(state.databases.currentDatabase!.uri, key)
}

export const getUserSetupValue2 = async (db: string, key: string) => {
    const state = store.getState() as RootState
    if (!state.userSetup.setups[db] || state.userSetup.setups[db].status === 'invalid')
        await store.dispatch(loadUserSetup(db))
    return state.userSetup.setups[db].setup[key]
}

export const loadUserSetup = createAsyncThunk(
    'userSetup/loadUserSetup',
    async (db: string, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const response = await get(state, db + '/current_webuser_settings', { _fetch: 'all' })
        return { data: response.data }
    }
)

export const setUserSetupValue = createAsyncThunk(
    'userSetup/setUserSetupValue',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const parts = docPath.split('/');
        const response = await get(state, parts[0] + '/save_current_webuser_setting', { skey: field, svalue: val });
        return { data: response.data };
    }
)

export const setSecureUserSetupValue = createAsyncThunk(
    'userSetup/setSecureUserSetupValue',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const parts = docPath.split('/')
        const response = await get(state, parts[0] + '/save_current_webuser_setting', { skey: field, svalue: val })
        return { data: response.data }
    }
)

const updateJSON = (entry: string | undefined, field: string, val: any) => {
    let obj
    if (entry)
        try {
            obj = JSON.parse(entry)
        } catch (e) {
            obj = {}
        }
    else
        obj = {}
    console.log('in ', obj, ' will set ', field, ' to ', val)
    obj[field] = val
    return JSON.stringify(obj)
}

export const setUserSetupObjectValueNoUpload = createAsyncThunk(
    'userSetup/setUserSetupObjectValueNoUpload',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const p = splitDocPath(docPath)

        // const response = await get(
        //     state,  
        //     p.dbName + '/save_current_webuser_setting', 
        //     {
        //         skey: p.id, 
        //         svalue: updateJSON(state.userSetup.setups[p.dbName].setup[p.id], field, val)
        //     }
        // )

        return {
            data: [{
                updated_name: p.id,
                updated_value: updateJSON(state.userSetup.setups[p.dbName].setup[p.id], field, val)
            }]
        };

        // console.log ('response', response)
        // return { data: response.data }
    }
)

export const setUserSetupObjectValue = createAsyncThunk(
    'userSetup/setUserSetupObjectValue',
    async ({ docPath, field, val }: UpdateFieldDataPayload, thunkApi) => {
        const state = thunkApi.getState() as RootState
        const p = splitDocPath(docPath)

        const response = await get(
            state,
            p.dbName + '/save_current_webuser_setting',
            {
                skey: p.id,
                svalue: updateJSON(state.userSetup.setups[p.dbName].setup[p.id], field, val)
            }
        )

        console.log('response', response)
        return { data: response.data }
    }
)

export const userSetupSlice = createSlice({
    name: 'userSetup',
    initialState,
    reducers: {
        invalidate: (state, action: PayloadAction<string>) => {
            delete state.setups[action.payload]
        },
        invalidateAll: (state) => {
            state.setups = {}
        },

        setValueWithoutSaving: (state, action: PayloadAction<UpdateFieldDataPayload>) => {
            const p = splitDocPath(action.payload.docPath)
            console.log('setValueWithoutSaving', p, action.payload)
            // state.setups[p.dbName].setup[item.updated_name] = item.updated_value
            // state.setups[action.payload.docPath].setup[action.payload.field] = action.payload.val
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUserSetup.pending, (state, action) => {
                state.setups[action.meta.arg] = { status: 'loading', setup: {} }
                // console.log('pending', action)
            })
            .addCase(loadUserSetup.fulfilled, (state, action) => {
                state.setups[action.meta.arg].status = 'idle'
                state.setups[action.meta.arg].setup = {}
                action.payload!.data.forEach((item: any) => {
                    state.setups[action.meta.arg].setup[item.key] = item.value
                })
                // console.log('fulfilled', action.payload)
            })
            .addCase(loadUserSetup.rejected, (state, action) => {
                console.warn('loadUserSetup.rejected', state, action);
                state.setups[action.meta.arg].status = 'error'
                state.setups[action.meta.arg].setup = {}
                state.setups[action.meta.arg].lastError = action.error
            })

            .addCase(setUserSetupValue.pending, (state, action) => {
                // console.log('pending save setup', action);
            })
            .addCase(setUserSetupValue.fulfilled, (state, action) => {
                console.log('fulfilled save setup', action.payload);

                action.payload!.data.forEach((item: any) => {
                    const db = action.meta.arg.docPath.split('/')[0];
                    action.payload!.data.forEach((item: any) => {
                        state.setups[db].setup[item.updated_name] = item.updated_value;
                    })
                })
            })
            .addCase(setUserSetupValue.rejected, (state, action) => {
                console.warn(state, action);
            })

            .addCase(setSecureUserSetupValue.pending, (state, action) => {
                // console.log('pending save setup', action)
            })
            .addCase(setSecureUserSetupValue.fulfilled, (state, action) => {
                // console.log('fulfilled save setup', action.payload)
                // action.payload!.data.forEach((item: any) => {
                //     const db = action.meta.arg.docPath.split('/')[0]
                //     action.payload!.data.forEach((item: any) => {
                //         state.setups[db].setup[item.updated_name] = item.updated_value
                //     })    
                // })
            })
            .addCase(setSecureUserSetupValue.rejected, (state, action) => {
                // console.warn(state, action);
            })


            .addCase(setUserSetupObjectValue.pending, (state, action) => {
                console.log('pending save setup', action)
            })
            .addCase(setUserSetupObjectValue.fulfilled, (state, action) => {
                console.log('fulfilled save setup', action.payload)
                action.payload!.data.forEach((item: any) => {
                    const db = action.meta.arg.docPath.split('/')[0]
                    action.payload!.data.forEach((item: any) => {
                        state.setups[db].setup[item.updated_name] = item.updated_value
                    })
                })
            })
            .addCase(setUserSetupObjectValue.rejected, (state, action) => {
                console.warn(state, action);
            })

            .addCase(setUserSetupObjectValueNoUpload.pending, (state, action) => {
                console.log('pending save setup no upload', action)
            })
            .addCase(setUserSetupObjectValueNoUpload.fulfilled, (state, action) => {
                console.log('fulfilled save setup no upload', action);
                action.payload!.data.forEach((item: any) => {
                    const db = action.meta.arg.docPath.split('/')[0]
                    action.payload!.data.forEach((item: any) => {
                        state.setups[db].setup[item.updated_name] = item.updated_value;
                    });
                });

                const ps = splitDocPath(action.meta.arg.docPath);
                console.log('new data for ' + action.meta.arg.docPath, state.setups[ps.dbName].setup[ps.id]);

                // console.log('new data for ' + action.meta.arg.docPath, state.setups[action.meta.arg.docPath])
            })
            .addCase(setUserSetupObjectValueNoUpload.rejected, (state, action) => {
                console.warn(state, action);
            })

    },
});

export const { invalidate, invalidateAll } = userSetupSlice.actions;
export const selectUserSetupError = (db: string): any =>
    useSelector((state: RootState) => state.userSetup.setups[db]?.lastError)

// const getRegSelectorItems = createSelector(
//     [
//         (state: RootState) => state.selectors.list,
//         createIdSelector((path: string) => path) // TODO make function?
//     ],
//     (list, path) => list[path]?.list || []
// );

const getUserSetup = createSelector(
    [
        (state: RootState) => state.userSetup.setups,
        createIdSelector((db: string) => db)
    ],
    (setups, db) => setups[db]?.setup || {}
);

// export const selectUserSetup = (db: string): KeyValueList =>
//     getUserSetup(store.getState(), db);

export const selectUserSetup = (db: string): KeyValueList =>
    useSelector((state: RootState) => state.userSetup.setups[db]?.setup || {});

export const selectUserSetupStatus = (db: string | undefined): LoadableItemStatus =>
    useSelector((state: RootState) => db && state.userSetup.setups[db]?.status || 'invalid')

export const selectUserSetupValue = (db: string, key: string | undefined): string | undefined =>
    useSelector((state: RootState) => {
        // console.log('selectUserSetupValue', db, key, state.userSetup.setups[db]?.setup[key!])
        if (key === undefined)
            return undefined
        else
            return state.userSetup.setups[db]?.setup[key]
    })

const selUserSetupValue = createSelector(
    [
        (state: RootState) => state.userSetup.setups,
        createIdSelector((path: string) => path),
    ],
    (setups, path) => {
        const p = path.indexOf('/');
        if (p < 0)
            return {};
        const db = path.substr(0, p);
        const key = path.substr(p + 1);
        // const [db, key] = path.split('/');
        console.log('selUserSetupValue', db, key, setups[db]?.setup[key]);
        if (key !== undefined) {
            const value = setups[db]?.setup[key];
            if (value) {
                try {
                    console.log('sel ' + db + '/' + key + ': parsing ' + value);
                    return JSON.parse(value);
                } catch (e) {
                    console.warn(e);
                    return {};
                }
            }
        }
        console.log('sel ' + db + '/' + key + ': returning empty object');
        return {};
        // setups[db]?.setup[key] || ''
    }
);

export const selectUserSetupValueAsObject = (db: string, key: string | undefined): object =>
    selUserSetupValue(store.getState(), db + '/' + key);

// export const selectUserSetupValueAsObject = (db: string, key: string | undefined): object => {
//     console.log('selectUserSetupValueAsObject', db, key);
//     return useSelector(
//         useMemo(() =>
//         (state: RootState) => {
//             if(key === undefined)
//                 return {}
//             const value = state.userSetup.setups[db]?.setup[key];
//             console.log('value ' + db + '/' + key, value);
//             if(value)
//                 try {
//                     return JSON.parse(value)
//                 } catch (e) {
//                     return {}
//                 }
//             else
//                 return {}
//         }
//         , [db, key])
//     );
// }

export default userSetupSlice.reducer;