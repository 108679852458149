import { Draft, PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { FIELD_SEL, KeyValueList } from '../../app/types';
import { extractDocChild } from '../../app/utils';
import { del, get, post } from '../../profit/api';
import { getRegDescriptor } from '../../profit';
import * as Yup from 'yup';
import { splitDocPath, updateDocFieldValue } from './utils';
import { useSelector } from 'react-redux';
import { UpdateFieldDataPayload } from '../../app/types/DataConnector';
import { ApplicationUnit } from '../../profit/regs';

export type RecordStatus = 'invalid' | 'loading' | 'saving' | 'idle' | 'failed' | 'saved' | 'deleting' | 'deleted'
export type ValidationErrorsCollection = {[path: string]: string}

export interface RecordDescriptor {
    db: string
    registry: ApplicationUnit
    path: string

    record?: any
    recordStatus: RecordStatus
    operation?: string

    lastError?: any
}

export interface RecordDescriptorsList {
    [key: string]: RecordDescriptor
}

export interface DocsState {
    currentlyEdited?: UpdateFieldDataPayload,
    list: RecordDescriptorsList
}

const initialState: DocsState = {
    list: {}
}

const initialRecordState: RecordDescriptor = {
    db: '',
    path: '',
    recordStatus: 'invalid',
    registry: undefined,
}

export const deleteRecord = createAsyncThunk(
    'docs/doDeleteRecord',
    async (payload: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const dpi = splitDocPath(payload);
        const rd = getRegDescriptor(dpi.regName);
        const ret = await del(state, rd.getCorrectDocPath(payload));
        return ret;
    }
)

export interface LoadRecordPayload {
    docPath: string
    setup?: KeyValueList
}

export interface ExecuteAndReloadRecordPayload extends LoadRecordPayload {
    docPath: string,
    execute: string
}

const doLoadRecord = async (payload: LoadRecordPayload, additionalPath: string, thunkApi: any) => {
    const state = thunkApi.getState() as RootState

    if(!(state && state.docs && state.docs.list[payload.docPath].registry)) {

        // console.log('loadRecord, return early')
        return {}
    }

    const dli = state.docs.list[payload.docPath]
    const docToLoad = dli.record
    const setup = payload.setup || docToLoad?.__setup

    const rd = getRegDescriptor(dli.registry)
    // console.log('doLoadRecord:', rd.getCorrectDocPath(payload.docPath) + (additionalPath === '' ? '' : '/' + additionalPath));
    const response = await get(state, rd.getCorrectDocPath(payload.docPath) + (additionalPath === '' ? '' : '/' + additionalPath));
    // const response = await get(state, payload.docPath + (additionalPath === '' ? '' : '/' + additionalPath));

    const ret = await rd.afterLoad({
        ...response.data[0],
        __setup: setup || {},
        __db:  state.databases.currentDatabase!.uri,
        __fullPath: payload.docPath
    })

    return rd.forceAppendChildrenEmptyRows(ret)
}

// TODO
export const copyRecord = createAsyncThunk(
    'docs/copyRecord',
    async (payload: LoadRecordPayload, thunkApi) => {
        console.log('will copy record ', payload)
        const state = thunkApi.getState() as RootState
        const docToCopy = state && state.docs && state.docs.list[payload.docPath].registry ?
            state.docs.list[payload.docPath].record
            : await doLoadRecord(payload, '', thunkApi)
        const rd = getRegDescriptor(state.docs.list[payload.docPath].registry)
        return await rd.afterCopy(docToCopy)
    }
)

export const loadRecord = createAsyncThunk(
    'docs/loadRecord',
    async (payload: LoadRecordPayload, thunkApi) => await doLoadRecord(payload, '', thunkApi)
)

const doSaveRecord = async(docPath: string, additionalPath: string, thunkApi: any) => {
    const state = thunkApi.getState() as RootState;

    const docRecord = !!state.docs.currentlyEdited
        ? {...state.docs.list[docPath], record: await doUpdateFieldData(state.docs.currentlyEdited, thunkApi)}
        : state.docs.list[docPath];

    console.log('saving record: ', docPath, docRecord);

    const rd = getRegDescriptor(docRecord.registry);
    try {
        const doc = rd.beforeSave ? await rd.beforeSave(docRecord.record) : docRecord.record
        console.log('saveRecord', docPath, doc)
        const response = await post(state, rd.getCorrectDocPath(docPath) + (additionalPath === '' ? '' : '/' + additionalPath), doc);
        return await rd.afterLoad(rd.forceAppendChildrenEmptyRows(response.data[0]));
    } catch(e) {
        console.error(e);
        if(e instanceof Yup.ValidationError) {
            const ve: ValidationErrorsCollection = {}
            e.inner.forEach(err => {
                if(err.path) {
                    const p1 = err.path
                        .replace('.', '/')
                        .replace('[', '/')
                        .replace('].', '/')
                    ve[p1] = err.message
                }
            })
            return thunkApi.rejectWithValue({validationErrors: ve})
        }
        throw e
    }
}

export const saveRecord = createAsyncThunk(
    'docs/saveRecord',
    async (payload: string, thunkApi) => await doSaveRecord(payload, '', thunkApi)
)

export const executeAndReload = createAsyncThunk(
    'docs/executeAndReload',
    async (payload: ExecuteAndReloadRecordPayload, thunkApi) => doSaveRecord(payload.docPath, payload.execute, thunkApi)
)

export const emailDocument = createAsyncThunk(
    'docs/emailDocument',
    async (payload: string, thunkApi) => {

        // TODO - this is a hack to save the record before sending email
        // no need to append empty rows here, as it is done in doSaveRecord
        await doSaveRecord(payload, '', thunkApi); 

        console.log('emailing: ', payload);

        const state = thunkApi.getState() as RootState
        // const docRecord = state.docs.list[payload]
        // const rd = getRegDescriptor(docRecord.registry)

        const dp = splitDocPath(payload);
        if(dp.regName !== 'emails')
            throw new Error('emailDocument: wrong registry: ' + dp.regName);
        const email_id = Number.parseInt(dp.id);
        if(isNaN(email_id) || !email_id)
            throw new Error('emailDocument: wrong email id: ' + dp.id);

        const url = dp.dbName + '/send_email/' + email_id;
        const response = await get(state, url);
        return response.data;
    }
)

const doUpdateFieldData = async (params: UpdateFieldDataPayload, thunkApi: any) => {
    const {docPath, field, val} = params;
    const state = (thunkApi.getState() as RootState)
    // console.log('updateFieldData: ' + field + ' := "' + val + '"');
    const rd = getRegDescriptor(state.docs.list[docPath].registry)
    let record = updateDocFieldValue(state.docs.list[docPath].record, field, val)
    // console.log('updated record: ', record)

    try {
        const ve = {...record.validationErrors}
        if(ve && ve[field]) {
            delete ve[field]
        }

        if (rd && rd.validateField)
        try {
            const df = await rd.validateField(record, field)
            // console.log('validated record: ', record)
        } catch (e) {
            if(e instanceof Yup.ValidationError) {
                e.inner.forEach(err => {
                    if(err.path) {
                        const p1 = err.path
                            .replace('.', '/')
                            .replace('[', '/')
                            .replace('].', '/')
                        ve[p1] = err.message
                    }
                })
            } else
                console.log('NB! updateFieldData: validateField unexpected exception: ', e)
        }
        const ret = ve ? {...record, validationErrors: ve} : record
        const docRowsChecked = rd && rd.check_row_updated ? await rd.check_row_updated(ret, field, val) : ret
        const docUpdated = rd && rd.onUpdate ? await rd.onUpdate(docRowsChecked, field, val) : docRowsChecked

        return docUpdated
    }
    catch (e) {
        console.error('updateFieldData error: ', e)
        throw e
    }
}

export const updateFieldData = createAsyncThunk(
    'docs/updateFieldData',
    async (payload: UpdateFieldDataPayload, thunkApi) => {
        return doUpdateFieldData(payload, thunkApi);
    }
)

export const docsSlice = createSlice({
    name: 'docs',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        resetRecord: (state, action) => {
            state.list[action.payload] = initialRecordState
        },

        setRegistry: (state, action) => {
            const {registry, docPath} = action.payload
            if(state.list[docPath])
                state.list[docPath].registry = action.payload
            else
                console.warn('setRegistry: docPath not found: ', docPath)
        },

        setCurrentlyEdited(state, action: PayloadAction<UpdateFieldDataPayload>) {
            state.currentlyEdited = action.payload
        },

        resetCurrentlyEdited(state) {
            state.currentlyEdited = undefined
        },

        resetRecordError:  (state, action: PayloadAction<string>) => {
            state.list[action.payload].lastError = undefined
            state.list[action.payload].recordStatus = 'idle'
        },

        setRecordStatus: (state, action: PayloadAction<{docPath: string, status: RecordStatus}>) => {
            const {docPath, status} = action.payload
            state.list[docPath].recordStatus = status;
        },

        undoRecord: (state, action) => {
            state.list[action.payload].recordStatus = 'invalid'
        },

        setActiveRow: (state, action: PayloadAction<{docPath: string, rowPath: string}>) => {
            const {docPath, rowPath} = action.payload;
            state.list[docPath].record.__activeRow = rowPath;
        },

        appendRecordChild: (state, action) => {
            // console.log('appendRowToRecord', action)
            const {docPath, path} = action.payload

            const collection = extractDocChild(state.list[docPath].record, path)
            collection.push({});
            const enumerated = getRegDescriptor(state.list[docPath].registry).enumerateCollection(path, collection)
            state.list[docPath].record = updateDocFieldValue(state.list[docPath].record, path, enumerated);
        },

        deleteSelectedChildren: (state, action: PayloadAction<{docPath: string, path: string}>) => {
            const {docPath, path} = action.payload
            // console.log('deleteSelectedChildren for ', action.payload, state.list[docPath]?.record)
            const rd = getRegDescriptor(state.list[docPath].registry);
            const collection = extractDocChild(state.list[docPath].record, path).filter((i: any) => !i[FIELD_SEL]);
            const enumerated = rd.enumerateCollection(path, collection)
            state.list[docPath].record = rd.onDeleteRows(updateDocFieldValue(state.list[docPath].record, path, enumerated), path);
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // executeAndReload
            .addCase(executeAndReload.pending, (state, action) => {
                console.log('executeAndReload.pending', state, action);
                const {docPath, execute} = action.meta.arg;
                state.list[docPath].recordStatus = 'saving';
                state.list[docPath].operation = execute;
            })
            .addCase(executeAndReload.fulfilled, (state, action) => {
                console.log('executeAndReload.fulfilled', state, action);
                const {docPath} = action.meta.arg;
                state.list[docPath].recordStatus = 'saved';
                // state.list[docPath].record = action.payload;

                state.list[docPath].record = {
                    ...state.list[docPath].record,
                    ...action.payload,
                    __dirty: false,
                    __justSaved: true
                };

                state.list[docPath].db = action.payload.__db;
                // state.list[docPath].path = state.list[docPath].registry + '/' + (state.list[docPath].record && state.list[docPath].record.id ? state.list[docPath].record.id : 'new')
                state.currentlyEdited = undefined;

            })
            .addCase(executeAndReload.rejected, (state, action) => {
                console.error('executeAndReload.rejected: ', action)
                const {docPath} = action.meta.arg;
                state.list[docPath].recordStatus = 'failed';
                if(action.payload && (action.payload as any).validationErrors) {
                    const ve : ValidationErrorsCollection = (action.payload as any).validationErrors
                    state.list[docPath].record.validationErrors = ve
                    state.list[docPath].lastError = {message: 'Validation errors'}
                } else
                    state.list[docPath].lastError = action.error;
            })
            //loadRecord
            .addCase(loadRecord.pending, (state, action) => { 
                const {docPath} = action.meta.arg as any
                const {dbName, regName, id} = splitDocPath(docPath)

                if(id !== 'new' && state.list[dbName + '/' + regName + '/new']?.record?.__justSaved)
                    state.list[dbName! + '/' + regName! + '/new'].recordStatus = 'invalid'

                state.list[docPath] = {
                    ...initialRecordState,
                    recordStatus: 'loading',
                    registry: regName,
                    db: dbName,
                    path: regName + '/' + id,
                    record: undefined
                }
                // console.log('pending ', state.list[docPath], action.meta.arg)
            })
            .addCase(loadRecord.fulfilled, (state, action) => {
                const {docPath} = action.meta.arg as any;
                state.list[docPath].recordStatus = 'idle'
                // console.log('loadRecord.fulfilled: PAYLOAD: ', action.payload)
                state.list[docPath].record = action.payload
                state.list[docPath].db = action.payload.__db
                state.list[docPath].path = state.list[docPath].registry + '/' + (state.list[docPath].record && state.list[docPath].record.id ? state.list[docPath].record.id : 'new')
                state.currentlyEdited = undefined;
            })
            .addCase(loadRecord.rejected, (state, action) => { 
                console.warn('loadRecord.rejected', state, action);
                const {docPath} = action.meta.arg as any
                state.list[docPath].record = undefined;
                state.list[docPath].db = '';
                state.list[docPath].recordStatus = 'failed';
                state.list[docPath].lastError = action.error;
            })
            
            .addCase(copyRecord.pending, (state, action) => { 
                const docPath = action.meta.arg.docPath
                const {dbName, regName, id} = splitDocPath(docPath)
                const newDocPath = dbName + '/' + regName + '/new'
                state.list[newDocPath] = {
                    ...state.list[docPath],
                    path: regName + '/new',
                    db: dbName,
                    recordStatus: 'loading'
                }
                state.list[newDocPath].record.__fullPath = newDocPath
            })
            .addCase(copyRecord.fulfilled, (state, action) => {
                const docPath = action.meta.arg.docPath
                const {dbName, regName, id} = splitDocPath(docPath)
                const newDocPath = dbName + '/' + regName + '/new'

                // console.log('copyRecord.fulfilled', docPath + ' --> ' + newDocPath, 'action: ', action)

                state.list[newDocPath].record = {
                    ...action.payload,
                    __fullPath: newDocPath
                }
                state.list[newDocPath].path = regName + '/new'
                state.list[newDocPath].db = dbName
                state.list[newDocPath].recordStatus = 'idle'
                // console.log(newDocPath + ': ', state.list[newDocPath].recordStatus)
                state.currentlyEdited = undefined;
            })
            .addCase(copyRecord.rejected, (state, action) => { 
                console.warn('copyRecord.rejected', state, action);
            })

            //saveRecord
            .addCase(saveRecord.pending, (state, action) => { 
                // console.log('saveRecord.pending: ', action)
                const docPath = action.meta.arg;
                state.list[docPath].recordStatus = 'saving'; 
                state.list[docPath].operation = '';
            })
            .addCase(saveRecord.fulfilled, (state, action) => {
                // console.log('saveRecord.fulfilled', action)
                const docPath = action.meta.arg;
                state.list[docPath].recordStatus = 'saved';
                state.list[docPath].operation = '';
                state.list[docPath].record = {
                    ...state.list[docPath].record,
                    ...action.payload,
                    __dirty: false,
                    __justSaved: true
                }
            })
            .addCase(saveRecord.rejected, (state, action) => { 
                console.error('saveRecord.rejected: ', action)
                const docPath = action.meta.arg;
                state.list[docPath].recordStatus = 'failed';
                state.list[docPath].operation = '';
                if(action.payload && (action.payload as any).validationErrors) {
                    const ve : ValidationErrorsCollection = (action.payload as any).validationErrors
                    state.list[docPath].record.validationErrors = ve
                    state.list[docPath].lastError = {message: 'Validation errors'}
                } else
                    state.list[docPath].lastError = action.error;
            })
            
            //deleteRecord
            .addCase(deleteRecord.pending, (state, action) => { 
                const docPath = action.meta.arg as any
                state.list[docPath].recordStatus = 'deleting'; 
                // console.log('deleteRecord.pending: ', action)
            })
            
            .addCase(deleteRecord.fulfilled, (state, action) => {
                const docPath = action.meta.arg
                state.list[docPath].recordStatus = 'deleted';
                state.list[docPath].record = undefined;
            })

            .addCase(deleteRecord.rejected, (state, action) => {
                const docPath = action.meta.arg
                state.list[docPath].recordStatus = 'failed'
                state.list[docPath].lastError = action.error
                console.warn('deleteRecord.rejected', state, action)
            })

            .addCase(updateFieldData.fulfilled, (state, action) => {
                // console.log('updateFieldData.fulfilled', action)
                const docPath = action.meta.arg.docPath
                state.list[docPath].record = action.payload
                if(state.list[docPath].recordStatus === 'saved')
                    state.list[docPath].recordStatus = 'idle'
                // state.list[docPath].validationErrors = action.payload.validationErrors
                state.currentlyEdited = undefined;
            })
            .addCase(updateFieldData.rejected, (state, action) => {
                console.warn(action.error)
            })
            .addCase(updateFieldData.pending, (state, action) => {
                // console.log('updateFieldData.pending', action)
            })

            .addCase(emailDocument.pending, (state, action) => {
                const docPath = action.meta.arg;
                console.log('emailDocument.pending', docPath);
                state.list[docPath].recordStatus = 'saving';
                state.list[docPath].operation = 'emailing';
            })
            .addCase(emailDocument.fulfilled, (state, action) => {
                const docPath = action.meta.arg;
                state.list[docPath].recordStatus = 'invalid';
                // state.list[docPath].record = undefined;
            })
            .addCase(emailDocument.rejected, (state, action) => {
                const docPath = action.meta.arg;
                state.list[docPath].recordStatus = 'failed';
                state.list[docPath].lastError = action.error;
            })
    },
});

export const { 
    resetRecordError, setRecordStatus, appendRecordChild, setRegistry, 
    deleteSelectedChildren, undoRecord, resetRecord, setCurrentlyEdited, 
    resetCurrentlyEdited, setActiveRow } = docsSlice.actions;

export const selectDocuments = (state: RootState) => state.docs.list;

export const selectDocumentRecord = (docPath: string) =>
    useSelector((state: RootState) => state.docs.list[docPath])

export const selectLastError = (docPath: string) => 
    useSelector((state: RootState) => state.docs.list[docPath]?.lastError)
export const selectRecord = (docPath: string) => 
    useSelector((state: RootState) => state.docs.list[docPath]?.record)
export const selectRecordLocallyModified = (docPath: string) => 
    useSelector((state: RootState) => !!state.docs.list[docPath] && !!state.docs.list[docPath].record && !!state.docs.list[docPath].record.__dirty)
export const selectRecordStatus = (docPath: string) => 
    useSelector((state: RootState) => state.docs.list[docPath]?.recordStatus)
export const selectRecordOperation = (docPath: string) =>
    useSelector((state: RootState) => state.docs.list[docPath]?.operation)
export const selectRecordPath = (docPath: string) => 
    useSelector((state: RootState) => state.docs.list[docPath]?.path)
export const selectDb = (docPath: string) => 
    useSelector((state: RootState) => state.docs.list[docPath]?.db)

export default docsSlice.reducer;